import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(
    private messageService: MessageService
  ) {}

  closeToast(){
    this.messageService.clear();
  }

  showSuccess(detail = 'Message Content') {
    this.messageService.add({ key: 'global', severity: 'success',  summary: 'Sucesso', detail });
  }

  showInfo(detail = 'Message Content') {
    this.messageService.add({ key: 'global', severity: 'info', summary: 'Informações', detail });
  }

  showWarn(detail = 'Message Content') {
    this.messageService.add({ key: 'global', severity: 'warn', summary: 'Atenção', detail });
  }

  showError(detail = 'Message Content') {
    this.messageService.add({ key: 'global', severity: 'error', summary: 'Erro', detail });
  }

}
