import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './auth.config';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../shared/service/localstorage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  loggedInEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public conectar() {
    this.oauthService.loadDiscoveryDocument(environment.ssc_discovery)
      .then(doc => {
        this.oauthService.tryLoginCodeFlow({
          disableNonceCheck: true,
          onTokenReceived: (context) =>this.loggedInEmitter.emit(true),
        })
          .catch(err => { console.log(err) })
          .then((rs) => {
            if (this.oauthService.hasValidAccessToken()) {

              this.localStorage.save('user_info',
                sessionStorage.getItem('id_token_claims_obj') as string
              )
              this.localStorage.save('token',
                sessionStorage.getItem('id_token') as string
              )
              this.router.initialNavigation();
              // this.router.navigate(['/']);
              // if (
              //   this.router.url.indexOf('?code=') > 0 ||
              //   this.router.url == null ||
              //   this.router.url == '/'
              // ) {
              //   this.router.navigate(['/']);
              // } else this.router.navigate([this.router.url]);

            } else {
              this.oauthService.initCodeFlow();
            }
          });
      });
  }

  public logout() {
    this.localStorage.clearData();
    this.oauthService.logOut();
    sessionStorage.clear()
  }

  public hasRole(): any {
    try {
      const unidadePapeis = this.oauthService.getIdentityClaims()['jsonUnidadePapeis'];
      return JSON.parse(unidadePapeis).unidade.papeis; //.filter(papel => roles.includes(papel.codigo));
    } catch (err: any) {
      console.error('Erro ao recuperar permissões', err.message);
      //this.router.navigate(['acesso-negado'])
      return false;
    }

  }

  public async login() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.setupAutomaticSilentRefresh();

    await this.oauthService
      .loadDiscoveryDocument(environment.ssc_discovery)
      .then((doc) => {
        this.oauthService
          .tryLoginCodeFlow({ disableNonceCheck: true,
            onTokenReceived: (context) => this.loggedInEmitter.emit(true),
          })
          .catch((err) => {})
          .then(async () => {
            if (!this.oauthService.hasValidAccessToken()) {
              this.oauthService.initCodeFlow();
            } else {
              if (
                this.router.url.indexOf('?code=') > 0 ||
                this.router.url == null ||
                this.router.url == '/'
              ) {
                this.router.initialNavigation();
              } else this.router.navigate([this.router.url]);
            }
          });
      });

    // Inscreva-se nos eventos para atualizar o contador de tempo
    // this.oauthService.events
    //   .pipe(filter((event: OAuthEvent) => event.type === 'token_received'))
    //   .subscribe(() => {
    //     this.updateSessionTimeout();
    //   });
  }

}
