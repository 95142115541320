import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  loading$ = new Subject<boolean>();

  constructor() { }

  showLoading(status: any) {
    this.loading$.next(status);
  }

}
