export const environment = {
 
  production: true,

  appUrl: 'argos.mg.gov.br',
  apiUrl: 'https://argos.mg.gov.br/*',
  service: 'https://backend.argos.mg.gov.br/api/v1',
  
  ssc_issuer: 'https://ssc.prodemge.gov.br/ssc-idp-frontend/',
  ssc_redirectUri: 'https://argos.mg.gov.br/',
  ssc_clientId: 'IpEFGePdiF7MhGoUBgCZMYKY-14102021-argos',
  ssc_clientSecret: 'wwO6tHIpLNcAsYZJiklVpFgJ',
  ssc_scope: 'openid gord:oauth001 email offline_access',
  ssc_discovery:'https://ssc.prodemge.gov.br/ssc-idp-frontend/rest/oidc/discovery',
  authorization_ssc: 'Basic SXBFRkdlUGRpRjdNaEdvVUJnQ1pNWUtZLTE0MTAyMDIxLWFyZ29zOnd3TzZ0SElwTE5jQXNZWkppa2xWcEZnSg==',    // ssc_clientId:ssc_clientSecret (em base64)
};

console.log = function():void{}; 
