import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoVinculo'
})
export class TipoVinculoPipe implements PipeTransform {

  public tipo_vinculo: any = [
		{ id:"1", isCpf: false, tipo:"Sócio"},
		{ id:"2", isCpf: false, tipo:"Coobrigado"},
		{ id:"3", isCpf: false, tipo:"Procurador"},
		{ id:"4", isCpf: false, tipo:"Advogado"},
		{ id:"5", isCpf: false, tipo:"Intermediário"},
		{ id:"6", isCpf: false, tipo:"Investidor"},
		{ id:"7", isCpf: false, tipo:"Empregado"},
		{ id:"8", isCpf: false, tipo:"Presidente"},
		{ id:"9", isCpf: false, tipo:"Diretor"},
		{ id:"10", isCpf: false, tipo:"Não Classificado"},
		
		{ id:"11", isCpf: true, tipo:"Pai"},
		{ id:"12", isCpf: true, tipo:"Mãe"},
		{ id:"13", isCpf: true, tipo:"Filho(a)"},
		{ id:"14", isCpf: true, tipo:"Cônjuge"},
		{ id:"15", isCpf: true, tipo:"Irmão(a)"},
		{ id:"16", isCpf: true, tipo:"Avô/Avó"},
		{ id:"17", isCpf: true, tipo:"Tio(a)"},
		{ id:"18", isCpf: true, tipo:"4º Grau acima"},
		{ id:"19", isCpf: true, tipo:"Tutor"},
		{ id:"20", isCpf: true, tipo:"Curador"},
		{ id:"21", isCpf: true, tipo:"Tutelado"},
		{ id:"22", isCpf: true, tipo:"Curatelado"},
		{ id:"23", isCpf: true, tipo:"Proprietário Sidagro"},
		{ id:"24", isCpf: true, tipo:"Fiador"},
        { id:"25", isCpf: true, tipo:"Cônjuge do Fiador"},
	]

	transform(value: any): any {
		if (value === '' || value == null || value === undefined)
			return value;
		//console.log(value);
		let tipo = this.tipo_vinculo.find( (v:any) => v.id == value);
		return tipo? tipo.tipo : 'Não informado';
	}

}
