import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  key = "gord";

  constructor() { }

  public save(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public get(key: string) {
    let data = localStorage.getItem(key) || "";
    return data;
  }

  public saveCrypt(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  public getCrypt(key: string) {
    let data = localStorage.getItem(key) || "";
    return this.decrypt(data);
  }

  // public getItem(key: string) {
  //   let data = localStorage.getItem(key) || "";
  //   console.log(data)
  //   return JSON.parse( data )
  // }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }
}

