import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.ssc_issuer,

  redirectUri: environment.ssc_redirectUri,

  silentRefreshRedirectUri: environment.ssc_redirectUri,

  clientId: environment.ssc_clientId,

  dummyClientSecret: "FYriReqY17bpSiZPH96A0XAV",

  responseType: 'code',

  scope: environment.ssc_scope,

  postLogoutRedirectUri: environment.ssc_redirectUri,
  oidc: true,
  showDebugInformation: true,
  sessionChecksEnabled: false,
  disableAtHashCheck: false,

};
